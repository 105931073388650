<template>
  <v-row class="justify-center align-self-start">
    <v-col class="col-4 mt-3">
      <v-card class="pa-3">
        <v-card-title class="heading-1 darkestblue--text">
          Custom Reports
        </v-card-title>

        <v-card-text
          v-if="!currentCustomReportOptions || !currentCustomReportOptions.length"
        >
          <v-row>
            <p class="px-4 pt-3">
              No reports found.
            </p>

            <p class="px-4 pb-3">
              <a
                href="mailto:iadops@oao.freshdesk.com"
                target="_blank"
              >Contact us</a>
              if this is an unexpected response or would like to learn more about Custom Reports.
            </p>
          </v-row>
        </v-card-text>

        <v-card-text v-else>
          <v-row
            v-for="org in currentCustomReportOptions"
            :key="org.docId"
            class="pt-2"
          >
            <v-col>
              <v-row>
                <v-col class="pb-0">
                  <h4
                    v-if="isInternalUser || currentCustomReportOptions.length > 1"
                    class="darkestblue--text"
                  >
                    {{ org.docId || 'Unknown Document ID' }}
                  </h4>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0">
                  <v-list
                    dense
                    class="py-0"
                  >
                    <v-list-item
                      v-for="(customReport, crIndex) in org.access.permissions.customReports"
                      :key="crIndex"
                      :class="isInternalUser ? 'border-left mt-3' : ''"
                    >
                      <v-list-item-content class="pt-0">
                        <v-list-item-title>
                          <v-row class="mt-0">
                            <v-col class="subtitle-2 font-weight-bold">
                              <router-link
                                :to="`/custom-reports/${customReport.href}/${org.docId}`"
                              >
                                {{ customReport.name || 'Report Name Not Set' }}
                              </router-link>
                            </v-col>
                          </v-row>

                          <v-row
                            v-if="isInternalUser && isCustomReportUser"
                          >
                            <v-col class="pt-0">
                              <v-row>
                                <v-col :class="customReportUsersByBillingCode[org.docId].length ? 'py-0' : 'pt-0'">
                                  <span class="gray--text">
                                    {{ customReportUsersByBillingCode[org.docId].length }}
                                    {{ customReportUsersByBillingCode[org.docId].length === 1 ? 'User' : 'Users' }}
                                  </span>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col class="pa-0">
                                  <v-list
                                    dense
                                    class="pt-0"
                                  >
                                    <v-list-item
                                      v-for="(user, userIndex) in customReportUsersByBillingCode[org.docId]"
                                      :key="userIndex"
                                      class="px-3"
                                    >
                                      <v-list-item-content class="py-0">
                                        <v-list-item-title>
                                          <span class="midgray-text">
                                            {{ user.docId }}
                                          </span>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'CustomReportsList',
  computed: {
    ...mapAuthGetters(['isCustomReportUser', 'isInternalUser']),
    ...mapToolsState({
      searchResults: (state) => state.searchResults,
    }),
    customReportOptions() {
      return this.searchResults.endpoints['custom-reports']?.items?.list || []
    },
    currentCustomReportOptions() {
      return this.reportId
        ? this.customReportOptions.filter((option) => option.docId === this.reportId)
        : this.customReportOptions
    },
    customReportUsers() {
      const customReportUsers = this.searchResults.endpoints.person?.items?.list || []

      return customReportUsers.filter((user) => user.hasCustomReports)
    },
    customReportUsersByBillingCode() {
      return this.currentCustomReportOptions.reduce((customReportUsers, org) => {
        customReportUsers[org.billingCode] = this.getCustomReportUsersByBillingCode(org.billingCode)
        return customReportUsers
      }, {})
    },
  },
  methods: {
    getCustomReportUsersByBillingCode(billingCode) {
      return this.customReportUsers.filter((user) => user.isUserInternal || user.billingCodes?.includes(billingCode))
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.border-left {
  border-left: 3px solid $color-monochrome-offwhite;
}
</style>
